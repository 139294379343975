<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xxl-12">
          <SchoolList></SchoolList>
        </div>
      </div>
      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import SchoolList from '@/view/pages/panel/home/SchoolList';

export default {
  name: 'dashboard',
  components: {
    SchoolList
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Kontrol Paneli' }]);
  }
};
</script>
