<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="gutter-b">
    <card title="Çalıştığım Kurumlar">
      <v-data-table
        :headers="headers"
        :items="schools"
        :loading="loading"
        :server-items-length="total"
        :options.sync="options"
        multi-sort
      >
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{ name: 'panel-school', params: { schoolId: item.id } }"
          >
            {{ item.name }}
          </router-link>
        </template>
      </v-data-table>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Kurum Listesi',
      headers: [
        { text: 'Kurum Adı', value: 'name' },
        { text: 'İl', value: 'iller.il_adi' },
        { text: 'İlce', value: 'ilceler.ilce_adi' },
        { text: 'Semt', value: 'semtler.semt_adi' },
        { text: 'Mahalle', value: 'mahalleler.mahalle_adi' }
      ],
      schools: [],
      options: {
        itemsPerPage: 10
      },
      schoolAddModal: false,
      deletePromt: false,
      deleteId: null,
      loading: true,
      total: 0
    };
  },
  mounted() {
    this.getSchoolList();
  },
  methods: {
    async getSchoolList() {
      try {
        const {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          filter
        } = this.requestQuery;

        const sort = {};
        sortBy.map((item, index) => {
          const array = item.split('.');
          if (array.length === 1) {
            sort[item] = sortDesc[index] ? 'desc' : 'asc';
          } else {
            sort[array[0]] = {};
            sort[array[0]][array[1]] = sortDesc[index] ? 'desc' : 'asc';
          }
        });

        const { data } = await this.axios.get('panel/schools', {
          params: {
            page: {
              size: itemsPerPage,
              number: page
            },
            sort,
            filter
          }
        });
        this.loading = false;
        this.schools = data.items;
        this.total = data.totalCount;
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    requestQuery() {
      return this.options;
    }
  },
  watch: {
    requestQuery: {
      handler: 'getSchoolList',
      deep: true
    }
  }
};
</script>
